import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserParameters } from '../slices/authSlice';
import { STEEL_GRADE_INFO, PAINT_FINISH_INFO, WELD_SPEC_INFO, LOCATION_INFO } from './steelGradeConstants';
import { Info } from 'lucide-react';

const ParameterDisplay = () => {
  const parameters = useSelector(selectUserParameters);

  if (!parameters || parameters.length === 0) {
    return null;
  }

  const getDescription = (name, value) => {
    if (name.includes('steel_grade')) {
      const type = name.split('_')[1].toUpperCase();
      // Special case for HSS since it doesn't use the _SHAPES suffix
      const lookupKey = type === 'HSS' ? 'HSS' : `${type}_SHAPES`;
      return STEEL_GRADE_INFO[lookupKey]?.descriptions[value];
    } else if (name === 'default_paint_finish') {
      return PAINT_FINISH_INFO.descriptions[value];
    } else if (name === 'default_weld_specification') {
      return WELD_SPEC_INFO.descriptions[value];
    } else if (name === 'default_location') {
      return LOCATION_INFO.descriptions[value];
    }
    return null;
  };

  const sections = {
    'Steel Grades': parameters.filter(p => p.name.includes('steel_grade')),
    'General Settings': parameters.filter(p => !p.name.includes('steel_grade') && p.is_default),
    'Custom Parameters': parameters.filter(p => p.is_custom)
  };

  const formatParameterName = (name) => {
    // If it's a custom parameter (doesn't start with 'default_'), just return the name
    if (!name.startsWith('default_')) {
      return name;
    }
    // Otherwise, format the default parameter name
    return name.split('_').slice(1).map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-xl font-bold mb-6">Current Parameters</h3>
      {Object.entries(sections).map(([sectionName, params]) => (
        params.length > 0 && (
          <div key={sectionName} className="mb-6">
            <h4 className="text-lg font-semibold mb-4">{sectionName}</h4>
            <div className="grid grid-cols-1 gap-4">
              {params.map(param => (
                <div key={param.name} className="border-b pb-4">
                  <div className="flex items-center font-medium text-gray-700">
                    {formatParameterName(param.name)}
                    {(param.description || getDescription(param.name, param.value)) && (
                      <div className="group relative inline-block">
                        <Info className="w-4 h-4 text-gray-400 hover:text-gray-600 inline-block ml-1" />
                        <div className="invisible group-hover:visible absolute z-10 w-64 p-2 mt-1 text-sm text-white bg-gray-900 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity">
                          {param.description || getDescription(param.name, param.value)}
                          {param.units && <div className="mt-1 text-gray-300">Units: {param.units}</div>}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="text-lg mt-1">
                    {param.value}
                    {param.units && <span className="text-sm text-gray-500 ml-1">({param.units})</span>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default ParameterDisplay;