import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const API_PREFIX = '/client/api';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PREFIX}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials),
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Login failed');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (email, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PREFIX}/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      
      if (!response.ok) throw new Error('Failed to process request');
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PREFIX}/reset-password/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password })
      });
      
      if (!response.ok) throw new Error('Failed to reset password');
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const checkSession = createAsyncThunk('auth/checkSession', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${API_PREFIX}/check_session`, { credentials: 'include' });
    const data = await response.json();
    if (response.ok && data.isAuthenticated) {
      return { isAuthenticated: true, user: data.user };
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, thunkAPI) => {
  try {
    await fetch(`${API_PREFIX}/logout`, { method: 'POST', credentials: 'include' });
    return { isAuthenticated: false };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const fetchUsers = createAsyncThunk(
  'auth/fetchUsers',
  async (_, { rejectWithValue }) => {
    try {
      console.log('Fetching users...');
      const response = await fetch(`${API_PREFIX}/users`);
      if (!response.ok) throw new Error('Server error!');
      const data = await response.json();
      console.log('Fetched users:', data);
      return data;
    } catch (error) {
      console.error('Error in fetchUsers thunk:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const addUser = createAsyncThunk(
  'auth/addUser',
  async (userData, { getState }) => {
    const state = getState();
    console.log('New User data:', userData);
    console.log('Auth User Org:', state.auth.user?.organization?.id); 
    const orgId = state.auth.user?.organization?.id || userData.organization_id;
    
    if (!orgId) {
      throw new Error('Organization ID is required');
    }

    const dataWithOrg = {
      ...userData,
      organization_id: orgId
    };

    const response = await fetch(`${API_PREFIX}/users`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataWithOrg),
      credentials: 'include'
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error || 'Failed to create user');
    }
    return response.json();
  }
);

export const deleteUser = createAsyncThunk(
  'auth/deleteUser',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PREFIX}/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ admin_id: 1 }),
      });
      if (!response.ok) throw new Error('Server error!');
      return userId;
    } catch (error) {
      console.error('Error in deleteUser thunk:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchOrganizations = createAsyncThunk(
  'auth/fetchOrganizations',
  async (_, { rejectWithValue }) => {
    try {
      console.log('Making request to fetch organizations...');
      const response = await fetch('/client/api/organizations', {
        credentials: 'include'
      });
      console.log('Organizations response status:', response.status);
      if (!response.ok) throw new Error('Failed to fetch organizations');
      const data = await response.json();
      console.log('Fetched organizations data:', data);
      return data;
    } catch (error) {
      console.error('Error fetching organizations:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchOrganizationUsers = createAsyncThunk(
  'auth/fetchOrganizationUsers',
  async (orgId) => {
    try {
      console.log('Making request to fetch users for org:', orgId);
      const response = await fetch(`/client/api/organizations/${orgId}/users`, {
        credentials: 'include'  // Add credentials here too
      });
      console.log('Users response status:', response.status);
      if (!response.ok) throw new Error('Failed to fetch organization users');
      const data = await response.json();
      console.log('Fetched users data:', data);
      return data;
    } catch (error) {
      console.error('Error fetching organization users:', error);
      throw error;
    }
  }
);

export const addOrganization = createAsyncThunk(
  'auth/addOrganization',
  async (orgData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PREFIX}/organizations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orgData),
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Failed to create organization');
      return response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  'auth/deleteOrganization',
  async (orgId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PREFIX}/organizations/${orgId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to delete organization');
      return orgId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateOrganization = createAsyncThunk(
  'auth/updateOrganization',
  async ({ orgId, data }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PREFIX}/organizations/${orgId}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to update organization');
      return response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUserParameters = createAsyncThunk(
  'auth/fetchUserParameters',
  async (_, {rejectWithValue}) => {
    try {
      const response = await fetch(`${API_PREFIX}/user/parameters`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('failed to fetch parameters');
      return response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateUserParameters = createAsyncThunk(
  'auth/updateUserParameters',
  async (parameters, {rejectWithValue}) => {
    try {
      const response = await fetch(`${API_PREFIX}/user/parameters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(parameters),
      });
      if (!response.ok) throw new Error('Failed to update parameters');
      return response.json();
    } catch(error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchDefaultParameters=createAsyncThunk(
  'auth/fetchDefaultParameters',
  async (_,{rejectWithValue})=>{
    try {
      const response =await fetch(`${API_PREFIX}/defaults`,{
        credentials:'include'
      });
      if (!response.ok) throw new Error('Failed to fetch default parameters');
      return response.json();
    } catch (error){
      return rejectWithValue(error.message)
    }
  }
);

export const acceptNDA = createAsyncThunk(
  'auth/acceptNDA',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PREFIX}/user/accept-nda`, {
        method: 'POST',
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to accept NDA');
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createCustomParameter = createAsyncThunk(
  'auth/createCustomParameter',
  async (parameterData, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_PREFIX}/user/parameters/custom`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(parameterData),
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to create custom parameter');
      }
      
      return response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCustomParameter = createAsyncThunk(
  'auth/deleteCustomParameter',
  async (parameterName, {rejectWithValue}) => {
    try {
      const response = await fetch(`${API_PREFIX}/user/parameters/custom/${encodeURIComponent(parameterName)}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to delete custom parameter');
      return parameterName; // Return the deleted parameter name
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  user: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
  users: [],
  organizations: [],
  userStatus: 'idle',
  organizationsStatus: 'idle',
  parameters:{
    user:[],
    defaults:[],
    status:'idle',
    error:null,
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(checkSession.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload.isAuthenticated;
        state.user = action.payload.user;
      })
      .addCase(checkSession.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.error = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.userStatus = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.userStatus = 'loading';
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.userStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.userStatus = 'succeeded';
        state.users = [...state.users, action.payload];
      })
      .addCase(addUser.pending, (state) => {
        state.userStatus = 'loading';
      })
      .addCase(addUser.rejected, (state, action) => {
        state.userStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.userStatus = 'succeeded';
        state.users = state.users.filter(user => user.id !== action.payload);
      })
      .addCase(deleteUser.pending, (state) => {
        state.userStatus = 'loading';
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.userStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.organizationsStatus = 'succeeded';
        state.organizations = action.payload;
      })
      .addCase(addOrganization.fulfilled, (state, action) => {
        state.organizations.push(action.payload);
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.organizations = state.organizations.filter(org => org.id !== action.payload);
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        const index = state.organizations.findIndex(org => org.id === action.payload.id);
        if (index !== -1) {
          state.organizations[index] = action.payload;
        }
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.error = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.error = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchOrganizationUsers.pending, (state) => {
        state.userStatus = 'loading';
      })
      .addCase(fetchOrganizationUsers.fulfilled, (state, action) => {
        console.log('Setting users in state:', action.payload);
        state.userStatus = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchOrganizationUsers.rejected, (state, action) => {
        state.userStatus = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchUserParameters.fulfilled,(state,action)=>{
        state.parameters.status='succeeded';
        state.parameters.user=action.payload;
        state.parameters.error=null;
      })
      .addCase(updateUserParameters.fulfilled, (state, action) => {
        state.parameters.status = 'succeeded';
        // Directly set the user parameters from the action payload
        state.parameters.user = action.payload.map(param => ({
          name: param.name,
          value: param.value,
          is_default: param.is_default
        }));
        state.parameters.error = null;
      })
      .addCase(fetchDefaultParameters.fulfilled,(state,action)=>{
        state.parameters.status='succeeded';
        state.parameters.defaults=action.payload;
        state.parameters.error=null;

      })
      .addCase(fetchUserParameters.pending, (state) => {
        state.parameters.status = 'loading';
      })
      .addCase(fetchUserParameters.rejected, (state, action) => {
        state.parameters.status = 'failed';
        state.parameters.error = action.payload;
      })
      .addCase(updateUserParameters.pending, (state) => {
        state.parameters.status = 'loading';
      })
      .addCase(updateUserParameters.rejected, (state, action) => {
        state.parameters.status = 'failed';
        state.parameters.error = action.payload;
      })
      .addCase(fetchDefaultParameters.pending, (state) => {
        state.parameters.status = 'loading';
      })
      .addCase(fetchDefaultParameters.rejected, (state, action) => {
        state.parameters.status = 'failed';
        state.parameters.error = action.payload;
      })
      .addCase(acceptNDA.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(acceptNDA.fulfilled, (state) => {
        state.isLoading = false;
        if (state.user) {
          state.user.nda_accepted_at = new Date().toISOString();
        }
      })
      .addCase(acceptNDA.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createCustomParameter.fulfilled, (state, action) => {
        state.parameters.status = 'succeeded';
        // Add the new parameter to the list
        state.parameters.user.push(action.payload);
        state.parameters.error = null;
      })
      .addCase(createCustomParameter.pending, (state) => {
        state.parameters.status = 'loading';
      })
      .addCase(createCustomParameter.rejected, (state, action) => {
        state.parameters.status = 'failed';
        state.parameters.error = action.payload;
      })
      .addCase(deleteCustomParameter.pending, (state) => {
        state.parameters.status = 'loading';
      })
      .addCase(deleteCustomParameter.fulfilled, (state, action) => {
        state.parameters.status = 'succeeded';
        // Remove the deleted parameter from the list
        state.parameters.user = state.parameters.user.filter(
          param => param.name !== action.payload
        );
        state.parameters.error = null;
      })
      .addCase(deleteCustomParameter.rejected, (state, action) => {
        state.parameters.status = 'failed';
        state.parameters.error = action.payload;
      });
  }
});

export const selectUserParameters = (state) => state.auth.parameters.user;
export const selectDefaultParameters = (state) => state.auth.parameters.defaults;
export const selectParametersStatus = (state) => state.auth.parameters.status;
export const selectParametersError = (state) => state.auth.parameters.error;

export default authSlice.reducer;