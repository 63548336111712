import React, { useState } from 'react';
import { Upload, AlertCircle, X } from 'lucide-react';
import { Alert, AlertTitle, AlertDescription } from './Alert';
import { useDispatch, useSelector } from 'react-redux';
import { markPdfAsFailed, processPdf, updatePDFProcessedStatus } from '../slices/pdfSlice';

const ProcessPDFModal = ({ isOpen, onClose, pdfId, onSuccess }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.pdf?.loading) || false;
  
  const [files, setFiles] = useState({
    pdf: null,
    csv: null,
    xml: null
  });
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [showFailureOptions, setShowFailureOptions] = useState(false);
  const [failureDetails, setFailureDetails] = useState({
    reason: 'non_vector',
    notes: '',
    notifyClient: true
  });

  const handleFileChange = (type, e) => {
    const file = e.target.files[0];
    setFiles(prev => ({
      ...prev,
      [type]: file
    }));
  };

  const validateFiles = () => {
    if (!showFailureOptions && !files.pdf && !files.csv && !files.xml) {
      setError('At least one file is required');
      return false;
    }

    // Validate file types if they are present
    if (files.csv && !files.csv.name.toLowerCase().endsWith('.csv')) {
      setError('Invalid CSV file format');
      return false;
    }
    if (files.pdf && !files.pdf.name.toLowerCase().endsWith('.pdf')) {
      setError('Invalid PDF file format');
      return false;
    }
    if (files.xml && !files.xml.name.toLowerCase().endsWith('.xml')) {
      setError('Invalid XML file format');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFiles()) return;

    setIsUploading(true);
    setError(null);

    try {
      if (showFailureOptions) {
        // Handle marking as failed using Redux
        await dispatch(markPdfAsFailed({
          pdfId,
          status: 'failed',
          failureReason: failureDetails.reason,
          notes: failureDetails.notes,
          notifyClient: failureDetails.notifyClient
        })).unwrap();
      } else {
        // Handle file processing using Redux
        const formData = new FormData();
        if (files.pdf) formData.append('processed_pdf', files.pdf);
        if (files.csv) formData.append('csv_file', files.csv);
        if (files.xml) formData.append('xml_file', files.xml);

        try {
          // Process the PDF files
          await dispatch(processPdf({
            pdfId,
            formData
          })).unwrap();
          
          // Only update the processed status after successful processing
          await dispatch(updatePDFProcessedStatus({
            pdfId
          })).unwrap();
          
          console.log(`PDF ${pdfId} successfully processed`);
        } catch (err) {
          console.error(`Error processing PDF ${pdfId}:`, err);
          throw new Error(`Failed to process PDF: ${err.message}`);
        }
      }

      onSuccess();
      onClose();
      setFiles({ pdf: null, csv: null, xml: null });
      setFailureDetails({
        reason: 'non_vector',
        notes: '',
        notifyClient: true
      });
    } catch (err) {
      setError(err.message || 'Failed to process files');
    } finally {
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-xl w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Process PDF</h3>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {error && (
          <Alert className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="mb-4">
          <button
            type="button"
            onClick={() => setShowFailureOptions(!showFailureOptions)}
            className={`w-full px-4 py-2 text-sm font-medium rounded-md ${
              showFailureOptions
                ? 'bg-red-100 text-red-800 hover:bg-red-200'
                : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
            }`}
          >
            {showFailureOptions ? 'Cancel Failure' : 'Mark as Failed Instead'}
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {showFailureOptions ? (
            <>
              <div>
                <label className="block text-sm font-medium mb-2">
                  Failure Reason
                </label>
                <select
                  className="w-full p-2 border rounded-md"
                  value={failureDetails.reason}
                  onChange={(e) => setFailureDetails(prev => ({
                    ...prev,
                    reason: e.target.value
                  }))}
                >
                  <option value="non_vector">Non-Vector File</option>
                  <option value="corrupt">Corrupted File</option>
                  <option value="incomplete">Incomplete Information</option>
                  <option value="wrong_format">Wrong Format</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">
                  Notes
                </label>
                <textarea
                  className="w-full p-2 border rounded-md h-24"
                  placeholder="Add any additional notes about the failure..."
                  value={failureDetails.notes}
                  onChange={(e) => setFailureDetails(prev => ({
                    ...prev,
                    notes: e.target.value
                  }))}
                />
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="notifyClient"
                  checked={failureDetails.notifyClient}
                  onChange={(e) => setFailureDetails(prev => ({
                    ...prev,
                    notifyClient: e.target.checked
                  }))}
                  className="rounded border-gray-300"
                />
                <label htmlFor="notifyClient" className="text-sm">
                  Notify client about failure status
                </label>
              </div>
            </>
          ) : (
            <>
              <div>
                <label className="block text-sm font-medium mb-2">
                  Processed PDF File (optional)
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={(e) => handleFileChange('pdf', e)}
                    className="w-full"
                  />
                  {files.pdf && (
                    <span className="text-sm text-green-600">✓</span>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">
                  CSV Data File (optional)
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="file"
                    accept=".csv"
                    onChange={(e) => handleFileChange('csv', e)}
                    className="w-full"
                  />
                  {files.csv && (
                    <span className="text-sm text-green-600">✓</span>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">
                  XML Metadata File (optional)
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    type="file"
                    accept=".xml"
                    onChange={(e) => handleFileChange('xml', e)}
                    className="w-full"
                  />
                  {files.xml && (
                    <span className="text-sm text-green-600">✓</span>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border rounded hover:bg-gray-100"
              disabled={loading || isUploading}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading || isUploading || (!showFailureOptions && !files.pdf && !files.csv && !files.xml)}
              className={`flex items-center px-4 py-2 rounded ${
                showFailureOptions
                  ? 'bg-red-600 text-white hover:bg-red-700'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
              } disabled:opacity-50`}
            >
              <Upload className="w-4 h-4 mr-2" />
              {loading || isUploading
                ? 'Processing...'
                : showFailureOptions
                ? 'Mark as Failed'
                : 'Process Files'
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProcessPDFModal;