import React from "react";
import { Provider } from "react-redux";
import store from "./app/store";
import App from "./components/App";
import Modal from "react-modal";
import { createRoot } from "react-dom/client";
import "./index.css";
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

// Set the app element for react-modal
Modal.setAppElement("#root");

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
