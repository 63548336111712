import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const API_PREFIX = '/client/api';

export const fetchPDFs = createAsyncThunk(
  'pdfs/fetchPDFs',
  async () => {
    console.log('⏳ Initiating fetchPDFs request to:', `${API_PREFIX}/pdfs`);
    
    const response = await fetch(`${API_PREFIX}/pdfs`);
    console.log('📨 fetchPDFs response received:', {
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries([...response.headers]),
      ok: response.ok
    });
    
    if (!response.ok) throw new Error('Failed to fetch PDFs');
    
    // Get response text first to check if it's HTML
    const text = await response.text();
    console.log('📝 fetchPDFs response text preview:', text.substring(0, 200));
    
    // Check if the response is HTML (indicating a session timeout or login page)
    const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
    console.log('🔍 fetchPDFs response is HTML?', isHtml);
    
    if (isHtml) {
      console.error('⚠️ fetchPDFs received HTML instead of JSON - likely session expired');
      throw new Error('Session expired or authentication required');
    }
    
    // Attempt to parse as JSON if not HTML
    try {
      const data = JSON.parse(text);
      console.log('✅ fetchPDFs successfully parsed JSON data:', {
        type: typeof data,
        isArray: Array.isArray(data),
        length: Array.isArray(data) ? data.length : null
      });
      return data;
    } catch (error) {
      console.error('❌ fetchPDFs failed to parse response as JSON:', error);
      console.error('❌ Raw response that failed to parse:', text);
      throw new Error('Invalid response format from server');
    }
  }
);

export const fetchAllPDFs = createAsyncThunk(
  'pdfs/fetchAllPDFs',
  async () => {
    console.log('⏳ Initiating fetchAllPDFs request to:', `${API_PREFIX}/pdfs/all`);
    
    const response = await fetch(`${API_PREFIX}/pdfs/all`, {
      credentials: 'include'
    });
    console.log('📨 fetchAllPDFs response received:', {
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries([...response.headers]),
      ok: response.ok
    });
    
    if (!response.ok) throw new Error('Failed to fetch all PDFs');
    
    // Get response text first to check if it's HTML
    const text = await response.text();
    console.log('📝 fetchAllPDFs response text preview:', text.substring(0, 200));
    
    // Check if the response is HTML (indicating a session timeout or login page)
    const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
    console.log('🔍 fetchAllPDFs response is HTML?', isHtml);
    
    if (isHtml) {
      console.error('⚠️ fetchAllPDFs received HTML instead of JSON - likely session expired');
      throw new Error('Session expired or authentication required');
    }
    
    // Attempt to parse as JSON if not HTML
    try {
      const data = JSON.parse(text);
      console.log('✅ fetchAllPDFs successfully parsed JSON data:', {
        type: typeof data,
        isArray: Array.isArray(data),
        length: Array.isArray(data) ? data.length : null
      });
      return data;
    } catch (error) {
      console.error('❌ fetchAllPDFs failed to parse response as JSON:', error);
      console.error('❌ Raw response that failed to parse:', text);
      throw new Error('Invalid response format from server');
    }
  }
);

export const uploadPDF = createAsyncThunk(
  'pdfs/uploadPDF',
  async (formData) => {
    console.log('⏳ Initiating PDF upload request');
    
    const response = await fetch(`${API_PREFIX}/upload-pdf`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });
    
    console.log('📨 uploadPDF response received:', {
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries([...response.headers]),
      ok: response.ok
    });
    
    if (!response.ok) {
      // Get response text first to check if it's HTML
      const text = await response.text();
      console.log('📝 uploadPDF error response text preview:', text.substring(0, 200));
      
      // Check if the response is HTML (indicating a session timeout or login page)
      const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
      console.log('🔍 uploadPDF error response is HTML?', isHtml);
      
      if (isHtml) {
        console.error('⚠️ uploadPDF received HTML instead of JSON - likely session expired');
        throw new Error('Session expired or authentication required');
      }
      
      try {
        const errorData = JSON.parse(text);
        console.error('❌ uploadPDF server error:', errorData);
        throw new Error(errorData.message || 'Failed to upload PDF');
      } catch (parseError) {
        console.error('❌ Failed to parse error response:', parseError);
        throw new Error('Unknown error during PDF upload');
      }
    }
    
    // Get response text first to check if it's HTML
    const text = await response.text();
    console.log('📝 uploadPDF success response text preview:', text.substring(0, 200));
    
    // Check if the response is HTML (indicating a session timeout or login page)
    const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
    console.log('🔍 uploadPDF success response is HTML?', isHtml);
    
    if (isHtml) {
      console.error('⚠️ uploadPDF received HTML instead of JSON - likely session expired');
      throw new Error('Session expired or authentication required');
    }
    
    // Attempt to parse as JSON if not HTML
    try {
      const data = JSON.parse(text);
      console.log('✅ uploadPDF successfully uploaded PDF:', data);
      return data;
    } catch (error) {
      console.error('❌ uploadPDF failed to parse response as JSON:', error);
      console.error('❌ Raw response that failed to parse (first 500 chars):', text.substring(0, 500));
      throw new Error('Invalid response format from server');
    }
  }
);

export const updatePDFStatus = createAsyncThunk(
  'pdfs/updatePDFStatus',
  async ({ id, status }) => {
    const response = await fetch(`${API_PREFIX}/pdfs/${id}/status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status }),
    });
    if (!response.ok) throw new Error('Failed to update PDF status');
    return response.json();
  }
);

export const deletePDF = createAsyncThunk(
  'pdfs/deletePDF',
  async (pdfId) => {
    const response = await fetch(`${API_PREFIX}/pdfs/${pdfId}`, {
      method: 'DELETE',
    });
    if (!response.ok) throw new Error('Failed to delete PDF');
    return pdfId;
  }
);

export const fetchPDFData = createAsyncThunk(
  'pdfs/fetchPDFData',
  async (pdfId) => {
    console.log('⏳ Initiating fetchPDFData request for PDF ID:', pdfId);
    
    const response = await fetch(`${API_PREFIX}/pdfs/${pdfId}/data`);
    console.log('📨 fetchPDFData response received:', {
      pdfId,
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries([...response.headers]),
      ok: response.ok
    });
    
    if (!response.ok) throw new Error('Failed to fetch PDF data');
    
    // Get response text first to check if it's HTML
    const text = await response.text();
    console.log('📝 fetchPDFData response text preview:', text.substring(0, 200));
    
    // Check if the response is HTML (indicating a session timeout or login page)
    const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
    console.log('🔍 fetchPDFData response is HTML?', isHtml);
    
    if (isHtml) {
      console.error('⚠️ fetchPDFData received HTML instead of JSON - likely session expired');
      throw new Error('Session expired or authentication required');
    }
    
    // Attempt to parse as JSON if not HTML
    try {
      const data = JSON.parse(text);
      console.log('✅ fetchPDFData successfully parsed JSON data for PDF ID:', pdfId);
      return data;
    } catch (error) {
      console.error('❌ fetchPDFData failed to parse response as JSON:', error);
      console.error('❌ Raw response that failed to parse (first 500 chars):', text.substring(0, 500));
      throw new Error('Invalid response format from server');
    }
  }
);

export const exportPDFData = createAsyncThunk(
  'pdfs/exportPDFData',
  async ({ id, format }) => {
    const response = await fetch(`${API_PREFIX}/pdfs/${id}/export?format=${format}`);
    if (!response.ok) throw new Error('Failed to export PDF data');
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `pdf-export.${format}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
    return { id, format };
  }
);


export const fetchLocations = createAsyncThunk(
  'pdfs/fetchLocations',
  async () => {
    const response = await fetch(`${API_PREFIX}/locations`, {
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Failed to fetch locations');
    
    // Get response text first to check if it's HTML
    const text = await response.text();
    
    // Check if the response is HTML (indicating a session timeout or login page)
    if (text.startsWith('<!DOCTYPE') || text.startsWith('<html')) {
      throw new Error('Session expired or authentication required');
    }
    
    // Attempt to parse as JSON if not HTML
    try {
      return JSON.parse(text);
    } catch (error) {
      console.error('Failed to parse response as JSON:', error);
      throw new Error('Invalid response format from server');
    }
  }
);

export const addLocation = createAsyncThunk(
  'pdfs/addLocation',
  async (name) => {
    const response = await fetch(`${API_PREFIX}/locations`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name }),
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Failed to add location');
    return response.json();
  }
);

export const updateLocation = createAsyncThunk(
  'pdfs/updateLocation',
  async ({ id, name }) => {
    const response = await fetch(`${API_PREFIX}/locations/${id}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name }),
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Failed to update location');
    return response.json();
  }
);

export const deleteLocation = createAsyncThunk(
  'pdfs/deleteLocation',
  async (id) => {
    const response = await fetch(`${API_PREFIX}/locations/${id}`, {
      method: 'DELETE',
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Failed to delete location');
    return id;
  }
);

export const processPdf = createAsyncThunk(
  'pdfs/processPdf',
  async ({ pdfId, formData }) => {
    console.log('⏳ Initiating processPdf request for PDF ID:', pdfId);
    
    const response = await fetch(`${API_PREFIX}/pdfs/${pdfId}/process`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });
    
    console.log('📨 processPdf response received:', {
      pdfId,
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries([...response.headers]),
      ok: response.ok
    });
    
    if (!response.ok) {
      // Get response text first to check if it's HTML
      const text = await response.text();
      console.log('📝 processPdf error response text preview:', text.substring(0, 200));
      
      // Check if the response is HTML (indicating a session timeout or login page)
      const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
      console.log('🔍 processPdf error response is HTML?', isHtml);
      
      if (isHtml) {
        console.error('⚠️ processPdf received HTML instead of JSON - likely session expired');
        throw new Error('Session expired or authentication required');
      }
      
      try {
        const errorData = JSON.parse(text);
        console.error('❌ processPdf server error:', errorData);
        throw new Error(errorData.message || 'Failed to process PDF');
      } catch (parseError) {
        console.error('❌ Failed to parse error response:', parseError);
        throw new Error('Unknown error during PDF processing');
      }
    }
    
    // Get response text first to check if it's HTML
    const text = await response.text();
    console.log('📝 processPdf success response text preview:', text.substring(0, 200));
    
    // Check if the response is HTML (indicating a session timeout or login page)
    const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
    console.log('🔍 processPdf success response is HTML?', isHtml);
    
    if (isHtml) {
      console.error('⚠️ processPdf received HTML instead of JSON - likely session expired');
      throw new Error('Session expired or authentication required');
    }
    
    // Attempt to parse as JSON if not HTML
    try {
      const data = JSON.parse(text);
      console.log('✅ processPdf successfully processed PDF:', data);
      return data;
    } catch (error) {
      console.error('❌ processPdf failed to parse response as JSON:', error);
      console.error('❌ Raw response that failed to parse (first 500 chars):', text.substring(0, 500));
      throw new Error('Invalid response format from server');
    }
  }
);

export const updatePDFProcessedStatus = createAsyncThunk(
  'pdfs/updatePDFProcessedStatus',
  async ({ pdfId }) => {
    console.log('⏳ Initiating updatePDFProcessedStatus request for PDF ID:', pdfId);
    
    const response = await fetch(`${API_PREFIX}/pdfs/status/${pdfId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
      credentials: 'include'
    });
    
    console.log('📨 updatePDFProcessedStatus response received:', {
      pdfId,
      status: response.status,
      statusText: response.statusText,
      ok: response.ok
    });
    
    if (!response.ok) {
      const text = await response.text();
      
      const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
      if (isHtml) {
        throw new Error('Session expired or authentication required');
      }
      
      try {
        const errorData = JSON.parse(text);
        throw new Error(errorData.message || 'Failed to update PDF processed status');
      } catch (parseError) {
        throw new Error('Unknown error updating PDF processed status');
      }
    }
    
    const text = await response.text();
    try {
      const data = JSON.parse(text);
      console.log('✅ Successfully updated PDF processed status:', data);
      return { id: pdfId, ...data };
    } catch (error) {
      console.error('❌ Failed to parse response as JSON:', error);
      throw new Error('Invalid response format from server');
    }
  }
);

export const markPdfAsFailed = createAsyncThunk(
  'pdfs/markPdfAsFailed',
  async ({ pdfId, status, failureReason, notes, notifyClient }) => {
    console.log('⏳ Initiating markPdfAsFailed request for PDF ID:', pdfId);
    
    const response = await fetch(`${API_PREFIX}/pdfs/${pdfId}/status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status,
        failureReason,
        notes,
        notifyClient
      }),
      credentials: 'include'
    });
    
    console.log('📨 markPdfAsFailed response received:', {
      pdfId,
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries([...response.headers]),
      ok: response.ok
    });
    
    if (!response.ok) {
      // Get response text first to check if it's HTML
      const text = await response.text();
      console.log('📝 markPdfAsFailed error response text preview:', text.substring(0, 200));
      
      // Check if the response is HTML (indicating a session timeout or login page)
      const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
      console.log('🔍 markPdfAsFailed error response is HTML?', isHtml);
      
      if (isHtml) {
        console.error('⚠️ markPdfAsFailed received HTML instead of JSON - likely session expired');
        throw new Error('Session expired or authentication required');
      }
      
      try {
        const errorData = JSON.parse(text);
        console.error('❌ markPdfAsFailed server error:', errorData);
        throw new Error(errorData.message || 'Failed to mark PDF as failed');
      } catch (parseError) {
        console.error('❌ Failed to parse error response:', parseError);
        throw new Error('Unknown error marking PDF as failed');
      }
    }
    
    // Get response text first to check if it's HTML
    const text = await response.text();
    console.log('📝 markPdfAsFailed success response text preview:', text.substring(0, 200));
    
    // Check if the response is HTML (indicating a session timeout or login page)
    const isHtml = text.startsWith('<!DOCTYPE') || text.startsWith('<html');
    console.log('🔍 markPdfAsFailed success response is HTML?', isHtml);
    
    if (isHtml) {
      console.error('⚠️ markPdfAsFailed received HTML instead of JSON - likely session expired');
      throw new Error('Session expired or authentication required');
    }
    
    // Attempt to parse as JSON if not HTML
    try {
      const data = JSON.parse(text);
      console.log('✅ markPdfAsFailed successfully marked PDF as failed:', data);
      return data;
    } catch (error) {
      console.error('❌ markPdfAsFailed failed to parse response as JSON:', error);
      console.error('❌ Raw response that failed to parse (first 500 chars):', text.substring(0, 500));
      throw new Error('Invalid response format from server');
    }
  }
);

const initialState = {
  documents: [],
  allDocuments: [],
  extractedData: {},
  status: 'idle',
  error: null,
  currentPDF: null,
  loading: false,
  locations: {
    items: [],
    status: 'idle',
    error: null
  }
};

const pdfSlice = createSlice({
  name: 'pdfs',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setCurrentPDF: (state, action) => {
      state.currentPDF = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPDFs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPDFs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documents = action.payload;
        state.error = null;
      })
      .addCase(fetchPDFs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(uploadPDF.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadPDF.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documents.push(action.payload);
        state.error = null;
      })
      .addCase(uploadPDF.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deletePDF.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePDF.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documents = state.documents.filter(doc => doc.id !== action.payload);
        state.error = null;
      })
      .addCase(deletePDF.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPDFData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPDFData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.extractedData[action.payload.id] = action.payload.data;
        state.error = null;
      })
      .addCase(fetchPDFData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(exportPDFData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(exportPDFData.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(exportPDFData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updatePDFStatus.fulfilled, (state, action) => {
        const index = state.documents.findIndex(doc => doc.id === action.payload.id);
        if (index !== -1) {
          state.documents[index] = action.payload;
        }
      })
      .addCase(fetchAllPDFs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllPDFs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allDocuments = action.payload;
        state.error = null;
      })
      .addCase(fetchAllPDFs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLocations.pending, (state) => {
        state.locations.status = 'loading';
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.locations.status = 'succeeded';
        state.locations.items = action.payload;
        state.locations.error = null;
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.locations.status = 'failed';
        state.locations.error = action.error.message;
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        const index = state.locations.items.findIndex(loc => loc.id === action.payload.id);
        if (index !== -1) {
          state.locations.items[index] = action.payload;
        }
      })
      .addCase(addLocation.fulfilled, (state, action) => {
        state.locations.items = [...state.locations.items, action.payload];
      })
      
      .addCase(deleteLocation.fulfilled, (state, action) => {
        state.locations.items = state.locations.items.filter(loc => loc.id !== action.payload);
      })
      
      .addCase(processPdf.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(processPdf.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        
        const index = state.documents.findIndex(doc => doc.id === action.payload.id);
        if (index !== -1) {
          state.documents[index] = action.payload;
        }
        
        state.error = null;
      })
      .addCase(processPdf.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.error.message;
      })
      
      .addCase(updatePDFProcessedStatus.fulfilled, (state, action) => {
        const index = state.documents.findIndex(doc => doc.id === action.payload.id);
        if (index !== -1) {
          state.documents[index] = action.payload;
        }
      })
      
      .addCase(markPdfAsFailed.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(markPdfAsFailed.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false;
        
        const index = state.documents.findIndex(doc => doc.id === action.payload.id);
        if (index !== -1) {
          state.documents[index] = action.payload;
        }
        
        state.error = null;
      })
      .addCase(markPdfAsFailed.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearError, setCurrentPDF } = pdfSlice.actions;

// export const selectAllPDFs = (state) => state.pdfs.documents;
export const selectAllPDFs = (state) => {
  const user = state.auth.user;
  const pdfs = state.pdfs.documents;
  
  // Parent Admin sees everything
  if (user.role === 'parent_admin') {
    return pdfs;
  }
  
  // Regular admin sees organization PDFs
  if (user.role === 'admin') {
    return pdfs.filter(pdf => pdf.organization_id === user.organization?.id);
  }
  
  // Regular users see only their uploads
  return pdfs.filter(pdf => {
    // Handle both cases: when uploaded_by is an object or just a username
    const uploadedBy = typeof pdf.uploaded_by === 'object' ? pdf.uploaded_by.username : pdf.uploaded_by;
    return uploadedBy === user.username;
  });
};
export const selectPDFById = (state, pdfId) => 
  state.pdfs.documents.find(doc => doc.id === pdfId);
export const selectPDFStatus = (state) => state.pdfs.status;
export const selectPDFError = (state) => state.pdfs.error;
export const selectExtractedData = (state, pdfId) => 
  state.pdfs.extractedData[pdfId];
export const selectCurrentPDF = (state) => state.pdfs.currentPDF;

export default pdfSlice.reducer;