import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle, AlertDescription } from './Alert';
import { AlertCircle, Save, Check, X, Loader2 } from 'lucide-react';
import { 
  fetchUserParameters, 
  updateUserParameters,
  selectUserParameters,
  selectParametersStatus,
  selectParametersError,
  createCustomParameter,
  deleteCustomParameter
} from '../slices/authSlice';

import { STEEL_GRADE_INFO, PAINT_FINISH_INFO, WELD_SPEC_INFO,LOCATION_INFO } from './steelGradeConstants';
import { Info } from 'lucide-react';
import ParameterDisplay from './ParameterDisplay';

const DEFAULT_PARAMETER_NAMES = [
  'default_w_steel_grade',
  'default_t_steel_grade',
  'default_l_steel_grade',
  'default_hss_steel_grade',
  'default_pipe_steel_grade',
  'default_plate_steel_grade',
  'default_paint_finish',
  'default_weld_specification',
  'default_delivery_address',
  'domestic_steel_required'
];

const Tooltip = ({ children }) => (
  <div className="group relative inline-block">
    <Info className="w-4 h-4 text-gray-400 hover:text-gray-600 inline-block ml-1" />
    <div className="invisible group-hover:visible absolute z-10 w-64 p-2 mt-1 text-sm text-white bg-gray-900 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity">
      {children}
    </div>
  </div>
);

const SelectWithTooltip = ({ value, onChange, options, descriptions, label, name, hasUnsavedChanges, onSave, onBlur, fieldMessage, originalValue, onRevert, showIndividualButtons }) => (
  <div className="relative">
    {fieldMessage && (
      <div 
        className={`text-sm ${fieldMessage.type === 'success' ? 'text-green-600' : 'text-red-600'} font-medium mb-2 transition-opacity duration-300 ${fieldMessage.fadeOut ? 'opacity-0' : 'opacity-100'}`}
      >
        {fieldMessage.text}
      </div>
    )}
    <div className="flex justify-between items-center">
      <label className="block text-sm font-medium text-gray-700">
        {label}
        {value && (
          <Tooltip>
            {descriptions?.[value] || `No description available for ${value}`}
          </Tooltip>
        )}
      </label>
      {showIndividualButtons && hasUnsavedChanges && (
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onRevert(name)}
            className="px-2 py-1 text-xs border border-gray-300 text-gray-600 rounded hover:bg-gray-100"
          >
            Revert
          </button>
          <button
            onClick={() => onSave(name)}
            className="px-2 py-1 text-xs bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Save
          </button>
        </div>
      )}
    </div>
    <select
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      className={`mt-1 w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${
        hasUnsavedChanges ? 'border-red-500' : 'border-gray-300'
      }`}
    >
      <option value="">Select...</option>
      {options.map(option => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
  </div>
);

const SteelGradeSelect = ({ 
  value, 
  onChange, 
  options, 
  descriptions, 
  label,
  name,
  hasUnsavedChanges,
  onSave,
  onBlur,
  fieldMessage,
  originalValue,
  onRevert,
  showIndividualButtons
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current && !inputRef.current.contains(event.target) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    
    const filtered = options.filter(option => 
      option.toLowerCase().includes(newValue.toLowerCase())
    );
    
    setFilteredOptions(filtered);
    setIsOpen(true);
    onChange(newValue);
  };

  const handleSelectOption = (option) => {
    setInputValue(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      {fieldMessage && (
        <div 
          className={`text-sm ${fieldMessage.type === 'success' ? 'text-green-600' : 'text-red-600'} font-medium mb-2 transition-opacity duration-300 ${fieldMessage.fadeOut ? 'opacity-0' : 'opacity-100'}`}
        >
          {fieldMessage.text}
        </div>
      )}
      <div className="flex justify-between items-center">
        <label className="block text-sm font-medium text-gray-700">
          {label}
          {inputValue && (
            <Tooltip>
              {descriptions?.[inputValue] || `No description available for ${inputValue}`}
            </Tooltip>
          )}
        </label>
        {showIndividualButtons && hasUnsavedChanges && (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => onRevert(name)}
              className="px-2 py-1 text-xs border border-gray-300 text-gray-600 rounded hover:bg-gray-100"
            >
              Revert
            </button>
            <button
              onClick={() => onSave(name)}
              className="px-2 py-1 text-xs bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Save
            </button>
          </div>
        )}
      </div>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onBlur={(e) => {
            onBlur(e);
          }}
          placeholder={`Select or type ${label}...`}
          className={`mt-1 w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 ${
            hasUnsavedChanges ? 'border-red-500' : 'border-gray-300'
          }`}
        />
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="absolute inset-y-0 right-0 flex items-center px-2"
        >
          <svg 
            className="h-4 w-4 text-gray-400" 
            fill="none" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path d="M19 9l-7 7-7-7"></path>
          </svg>
        </button>
      </div>
      {isOpen && filteredOptions.length > 0 && (
        <ul 
          ref={dropdownRef}
          className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto"
        >
          {filteredOptions.map((option) => (
            <li
              key={option}
              onClick={() => handleSelectOption(option)}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer group"
            >
              <div>{option}</div>
              <div className="text-xs text-gray-500 group-hover:text-gray-700">
                {descriptions?.[option] || `No description available for ${option}`}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const GeneralSection = ({ parameters, onChange, fieldMessages, setFieldMessages, unsavedChanges, setUnsavedChanges, showIndividualButtons }) => {
  const dispatch = useDispatch();

  const handleParameterChange = (name, value) => {
    onChange(name, value);
    setUnsavedChanges(prev => ({
      ...prev,
      [name]: true
    }));
    setFieldMessages(prev => ({
      ...prev,
      [name]: null
    }));
  };

  const handleRevert = (name) => {
    const originalValue = parameters[name]?.originalValue;
    if (originalValue !== undefined) {
      onChange(name, originalValue);
      setUnsavedChanges(prev => {
        const newUnsaved = { ...prev };
        delete newUnsaved[name];
        return newUnsaved;
      });
      setFieldMessages(prev => ({
        ...prev,
        [name]: { type: 'success', text: 'Changes reverted' }
      }));
      setTimeout(() => {
        setFieldMessages(prev => ({
          ...prev,
          [name]: null
        }));
      }, 3000);
    }
  };

  const handleParameterBlur = (name) => {
    // No-op since we're not using focusedFields
  };

  const handleSaveParameter = async (name) => {
    try {
      const parameterUpdate = [{
        name,
        value: parameters[name].value
      }];

      // Set message to "Saving..." while the request is in progress
      setFieldMessages(prev => ({
        ...prev,
        [name]: { type: 'success', text: 'Saving...' }
      }));

      await dispatch(updateUserParameters(parameterUpdate)).unwrap();
      
      setUnsavedChanges(prev => {
        const newUnsaved = { ...prev };
        delete newUnsaved[name];
        return newUnsaved;
      });

      // Update to "Saved successfully" after the save completes
      setFieldMessages(prev => ({
        ...prev,
        [name]: { type: 'success', text: 'Saved successfully' }
      }));

      // Clear success message after 3 seconds
      setTimeout(() => {
        setFieldMessages(prev => ({
          ...prev,
          [name]: null
        }));
      }, 3000);
    } catch (err) {
      console.error(`Failed to update ${name}:`, err);
      setFieldMessages(prev => ({
        ...prev,
        [name]: { type: 'error', text: 'Failed to save' }
      }));
    }
  };

  return (
    <div className="space-y-4">
      <h4 className="text-lg font-medium">General Settings</h4>
      <div className="grid grid-cols-1 gap-4">
        <div>
          {fieldMessages['default_delivery_address'] && (
            <div className={`text-sm mb-1 ${fieldMessages['default_delivery_address'].type === 'success' ? 'text-green-600' : 'text-red-600'} font-medium`}>
              {fieldMessages['default_delivery_address'].text}
            </div>
          )}
          <div className="flex justify-between items-center">
            <label className="block text-sm font-medium text-gray-700">
              Default Delivery Address
              <Tooltip>Default delivery address for orders</Tooltip>
            </label>
            {showIndividualButtons && unsavedChanges['default_delivery_address'] && (
              <div className="flex items-center space-x-2">
                {fieldMessages['default_delivery_address'] && (
                  <span className={`text-sm ${fieldMessages['default_delivery_address'].type === 'success' ? 'text-green-600' : 'text-red-600'} font-medium`}>
                    {fieldMessages['default_delivery_address'].text}
                  </span>
                )}
                <button
                  onClick={() => handleRevert('default_delivery_address')}
                  className="px-2 py-1 text-xs border border-gray-300 text-gray-600 rounded hover:bg-gray-100"
                >
                  Revert
                </button>
                <button
                  onClick={() => handleSaveParameter('default_delivery_address')}
                  className="px-2 py-1 text-xs bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <textarea
            value={parameters.default_delivery_address?.value || ''}
            onChange={(e) => handleParameterChange('default_delivery_address', e.target.value)}
            onBlur={() => handleParameterBlur('default_delivery_address')}
            className={`mt-1 w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${
              unsavedChanges['default_delivery_address'] ? 'border-red-500' : 'border-gray-300'
            }`}
            rows={3}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Default Paint/Finish
            <Tooltip>
              {parameters.default_paint_finish?.description || 'Default paint or finish specification'}
            </Tooltip>
          </label>
          <SteelGradeSelect
            value={parameters.default_paint_finish?.value}
            onChange={(value) => handleParameterChange('default_paint_finish', value)}
            options={PAINT_FINISH_INFO.options}
            descriptions={PAINT_FINISH_INFO.descriptions}
            name="default_paint_finish"
            hasUnsavedChanges={unsavedChanges['default_paint_finish']}
            onSave={handleSaveParameter}
            onBlur={() => handleParameterBlur('default_paint_finish')}
            fieldMessage={fieldMessages['default_paint_finish']}
            originalValue={parameters.default_paint_finish?.originalValue}
            onRevert={handleRevert}
            showIndividualButtons={showIndividualButtons}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Default Weld Specification
            <Tooltip>
              {parameters.default_weld_specification?.description || 'Default welding specification'}
            </Tooltip>
          </label>
          <SteelGradeSelect
            value={parameters.default_weld_specification?.value}
            onChange={(value) => handleParameterChange('default_weld_specification', value)}
            options={WELD_SPEC_INFO.options}
            descriptions={WELD_SPEC_INFO.descriptions}
            name="default_weld_specification"
            hasUnsavedChanges={unsavedChanges['default_weld_specification']}
            onSave={handleSaveParameter}
            onBlur={() => handleParameterBlur('default_weld_specification')}
            fieldMessage={fieldMessages['default_weld_specification']}
            originalValue={parameters.default_weld_specification?.originalValue}
            onRevert={handleRevert}
            showIndividualButtons={showIndividualButtons}
          />
        </div>

        <div>
          <div className="flex justify-between items-center">
            <label className="block text-sm font-medium mb-2">
              Domestic Steel Required
              <Tooltip>
                {parameters.domestic_steel_required?.description || 'Whether domestic steel is required by default'}
              </Tooltip>
            </label>
            {showIndividualButtons && unsavedChanges['domestic_steel_required'] && (
              <div className="flex items-center space-x-2">
                {fieldMessages['domestic_steel_required'] && (
                  <span className={`text-sm ${fieldMessages['domestic_steel_required'].type === 'success' ? 'text-green-600' : 'text-red-600'} font-medium`}>
                    {fieldMessages['domestic_steel_required'].text}
                  </span>
                )}
                <button
                  onClick={() => handleRevert('domestic_steel_required')}
                  className="px-2 py-1 text-xs border border-gray-300 text-gray-600 rounded hover:bg-gray-100"
                >
                  Revert
                </button>
                <button
                  onClick={() => handleSaveParameter('domestic_steel_required')}
                  className="px-2 py-1 text-xs bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <select
            value={parameters.domestic_steel_required?.value || 'No'}
            onChange={(e) => handleParameterChange('domestic_steel_required', e.target.value)}
            onBlur={() => handleParameterBlur('domestic_steel_required')}
            className={`mt-1 w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${
              unsavedChanges['domestic_steel_required'] ? 'border-red-500' : 'border-gray-300'
            }`}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
    </div>
  );
};

const SteelGradeSection = ({ parameters, onChange, fieldMessages, setFieldMessages, unsavedChanges, setUnsavedChanges, showIndividualButtons }) => {
  const dispatch = useDispatch();

  const handleRevert = (name) => {
    const originalValue = parameters[name]?.originalValue;
    if (originalValue !== undefined) {
      onChange(name, originalValue);
      setUnsavedChanges(prev => {
        const newUnsaved = { ...prev };
        delete newUnsaved[name];
        return newUnsaved;
      });
      setFieldMessages(prev => ({
        ...prev,
        [name]: { type: 'success', text: 'Changes reverted' }
      }));
      setTimeout(() => {
        setFieldMessages(prev => ({
          ...prev,
          [name]: null
        }));
      }, 3000);
    }
  };

  const handleParameterChange = (name, value) => {
    onChange(name, value);
    setUnsavedChanges(prev => ({
      ...prev,
      [name]: true
    }));
    // Clear any existing message when field changes
    setFieldMessages(prev => ({
      ...prev,
      [name]: null
    }));
  };

  const handleParameterBlur = (name) => {
    // No-op since we're not using focusedFields
  };

  const handleSaveParameter = async (name) => {
    try {
      const parameterUpdate = [{
        name,
        value: parameters[name].value
      }];

      await dispatch(updateUserParameters(parameterUpdate)).unwrap();
      
      setUnsavedChanges(prev => {
        const newUnsaved = { ...prev };
        delete newUnsaved[name];
        return newUnsaved;
      });

      setFieldMessages(prev => ({
        ...prev,
        [name]: { type: 'success', text: 'Saved successfully' }
      }));

      // Clear success message after 3 seconds
      setTimeout(() => {
        setFieldMessages(prev => ({
          ...prev,
          [name]: null
        }));
      }, 3000);
    } catch (err) {
      console.error(`Failed to update ${name}:`, err);
      setFieldMessages(prev => ({
        ...prev,
        [name]: { type: 'error', text: 'Failed to save' }
      }));
    }
  };

  const steelGrades = [
    { key: 'default_w_steel_grade', label: 'W Shapes', info: STEEL_GRADE_INFO.W_SHAPES },
    { key: 'default_t_steel_grade', label: 'T Shapes', info: STEEL_GRADE_INFO.T_SHAPES },
    { key: 'default_l_steel_grade', label: 'L Shapes (Angles)', info: STEEL_GRADE_INFO.L_SHAPES },
    { key: 'default_hss_steel_grade', label: 'HSS', info: STEEL_GRADE_INFO.HSS },
    { key: 'default_pipe_steel_grade', label: 'Pipe', info: STEEL_GRADE_INFO.PIPE },
    { key: 'default_plate_steel_grade', label: 'Plate', info: STEEL_GRADE_INFO.PLATE }
  ];

  return (
    <div className="space-y-4">
      <h4 className="text-lg font-medium">Steel Grades</h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {steelGrades.map(({ key, label, info }) => (
          <div key={key}>
            <SteelGradeSelect
              value={parameters[key]?.value}
              onChange={(value) => handleParameterChange(key, value)}
              options={info.options}
              descriptions={info.descriptions}
              label={label}
              name={key}
              hasUnsavedChanges={unsavedChanges[key]}
              onSave={handleSaveParameter}
              onBlur={() => handleParameterBlur(key)}
              fieldMessage={fieldMessages[key]}
              originalValue={parameters[key]?.originalValue}
              onRevert={handleRevert}
              showIndividualButtons={showIndividualButtons}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const CustomParametersSection = ({ parameters, onChange, fieldMessages, setFieldMessages, unsavedChanges, setUnsavedChanges, showIndividualButtons }) => {
  const dispatch = useDispatch();
  // Filter only custom parameters using the is_custom flag
  const customParams = Object.entries(parameters)
    .filter(([name, param]) => param && param.is_custom === true);

  const handleDelete = async (parameterName) => {
    try {
      await dispatch(deleteCustomParameter(parameterName)).unwrap();
      dispatch(fetchUserParameters()); // Refresh the parameters list
    } catch (err) {
      console.error('Failed to delete parameter:', err);
    }
  };

  return (
    <div className="space-y-4">
      <h4 className="text-lg font-medium">Custom Parameters</h4>
      {customParams.length === 0 ? (
        <p className="text-sm text-gray-500">No custom parameters added yet. Use the form below to add custom parameters.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {customParams.map(([name, param]) => (
            <div key={`custom-param-${name}`}>
              <div className="flex justify-between items-center">
                <label className="block text-sm font-medium text-gray-700">
                  {param.parameter_name || name}
                  {param.description && <Tooltip>{param.description}</Tooltip>}
                </label>
                {showIndividualButtons && (
                  <button
                    onClick={() => handleDelete(name)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                )}
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={param.value || ''}
                  onChange={(e) => onChange(name, e.target.value)}
                  className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter value..."
                />
                {param.units && <span className="text-sm text-gray-500 mt-1">{param.units}</span>}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CustomParameterSection = ({ onParameterAdded, fieldMessages, setFieldMessages }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    parameter_name: '',
    parameter_value: '',
    category: 'MATERIAL',
    description: '',
    units: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      await dispatch(createCustomParameter(formData)).unwrap();
      setSuccess('Custom parameter created successfully');
      setFormData({
        parameter_name: '',
        parameter_value: '',
        category: 'MATERIAL',
        description: '',
        units: ''
      });
      if (onParameterAdded) {
        onParameterAdded();
      }
      setIsOpen(false); // Close accordion after successful submission
    } catch (err) {
      setError(err.toString());
    }
  };

  return (
    <div className="space-y-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex justify-between items-center py-2 text-lg font-medium text-gray-900 hover:text-gray-600 focus:outline-none"
      >
        <span>Add Custom Parameter</span>
        <svg
          className={`w-5 h-5 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      
      {isOpen && (
        <form onSubmit={handleSubmit} className="space-y-4 pt-4 border-t border-gray-200">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Parameter Name
                <Tooltip>
                  Enter a unique identifier for your custom parameter (e.g., "bolt_grade", "coating_type")
                </Tooltip>
              </label>
              <input
                type="text"
                name="parameter_name"
                value={formData.parameter_name}
                onChange={handleInputChange}
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Parameter Value
                <Tooltip>
                  Specify the default value for this parameter (e.g., "A325", "Galvanized")
                </Tooltip>
              </label>
              <input
                type="text"
                name="parameter_value"
                value={formData.parameter_value}
                onChange={handleInputChange}
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Category
                <Tooltip>
                  Select the category that best describes the type of parameter you're creating
                </Tooltip>
              </label>
              <select
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="MATERIAL">Material</option>
                <option value="PROCESSING">Processing</option>
                <option value="QUALITY">Quality</option>
                <option value="LOGISTICS">Logistics</option>
                <option value="PRICING">Pricing</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Units (optional)
                <Tooltip>
                  Specify the units of measurement if applicable (e.g., "inches", "ksi", "mm")
                </Tooltip>
              </label>
              <input
                type="text"
                name="units"
                value={formData.units}
                onChange={handleInputChange}
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700">
                Description (optional)
                <Tooltip>
                  Add a detailed explanation of what this parameter is used for and any relevant information
                </Tooltip>
              </label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                rows={3}
                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
          
          {error && (
            <Alert className="mt-4 bg-red-50">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          {success && (
            <Alert className="mt-4 bg-green-50">
              <AlertTitle>Success</AlertTitle>
              <AlertDescription>{success}</AlertDescription>
            </Alert>
          )}

          <button
            type="submit"
            className="mt-4 flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Add Custom Parameter
          </button>
        </form>
      )}
    </div>
  );
};

const ParametersManager = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const parameters = useSelector(selectUserParameters);
  const status = useSelector(selectParametersStatus);
  const error = useSelector(selectParametersError);
  const [localParameters, setLocalParameters] = useState({});
  const [fieldMessages, setFieldMessages] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const isFirstLogin = location.state?.isFirstLogin;
  const welcomeMessage = location.state?.message;
  const [saveStatus, setSaveStatus] = useState('idle'); // 'idle' | 'loading' | 'success' | 'error'
  const [revertStatus, setRevertStatus] = useState('idle'); // 'idle' | 'success' | 'error'
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [showRevertButton, setShowRevertButton] = useState(true);
  const [showIndividualButtons, setShowIndividualButtons] = useState(true);

  useEffect(() => {
    dispatch(fetchUserParameters());
  }, [dispatch]);

  const handleParameterChange = (name, value) => {
    setLocalParameters(prev => ({
      ...prev,
      [name]: { 
        ...prev[name], 
        value: value,
        originalValue: prev[name]?.originalValue === undefined ? prev[name]?.value : prev[name]?.originalValue
      }
    }));
    
    const originalValue = localParameters[name]?.originalValue;
    if (value !== originalValue) {
      setUnsavedChanges(prev => ({
        ...prev,
        [name]: true
      }));
      setShowSaveButton(true);
      setShowRevertButton(true);
    } else {
      setUnsavedChanges(prev => {
        const newUnsaved = { ...prev };
        delete newUnsaved[name];
        return newUnsaved;
      });
    }

    setFieldMessages(prev => {
      const newMessages = { ...prev };
      delete newMessages[name];
      return newMessages;
    });
  };

  useEffect(() => {
    if (parameters.length > 0) {
      const paramMap = parameters.reduce((acc, param) => {
        // Get the parameter name, handling both formats
        const paramName = param.parameter_name || param.name;
        // Get the parameter value, handling both formats
        const paramValue = param.parameter_value || param.value;
        
        // Check if this is a default parameter
        const isDefault = DEFAULT_PARAMETER_NAMES.includes(paramName);
        
        return {
          ...acc,
          [paramName]: {
            name: paramName,
            value: paramValue,
            originalValue: paramValue, // Store original value
            category: param.category,
            description: param.description,
            units: param.units,
            is_default: isDefault,
            is_custom: param.is_custom || false,
            parameter_name: param.parameter_name || param.name
          }
        };
      }, {});
      setLocalParameters(paramMap);
    }
  }, [parameters]);

  const handleSave = async () => {
    try {
      setShowRevertButton(false);
      setShowIndividualButtons(false);
      const changedParams = Object.keys(unsavedChanges);
      if (changedParams.length === 0) return;

      const parameterUpdates = Object.entries(localParameters)
        .filter(([name, param]) => unsavedChanges[name]) // Only send changed parameters
        .map(([name, param]) => ({
          name,
          value: param.value,
          is_default: param.is_default || false
        }));
  
      console.log('Sending parameters:', parameterUpdates);

      setSaveStatus('loading');
      // Clear unsaved changes immediately to remove borders
      setUnsavedChanges({});
      
      await dispatch(updateUserParameters(parameterUpdates)).unwrap();
      
      setSaveStatus('success');
      
      setTimeout(() => {
        setSaveStatus('idle');
        setShowSaveButton(false);
      }, 3000);
      
      if (isFirstLogin) {
        navigate('/');
      }
    } catch (err) {
      console.error('Failed to update parameters:', err);
      setSaveStatus('error');
      // Restore unsaved changes if there was an error
      setUnsavedChanges(prev => {
        const newUnsaved = {};
        Object.keys(localParameters).forEach(name => {
          if (localParameters[name].value !== localParameters[name].originalValue) {
            newUnsaved[name] = true;
          }
        });
        return newUnsaved;
      });
      setTimeout(() => {
        setSaveStatus('idle');
        setShowRevertButton(true);
        setShowSaveButton(true);
        setShowIndividualButtons(true);
      }, 3000);
    }
  };

  const handleRevertAll = () => {
    setShowSaveButton(false);
    setShowIndividualButtons(false);
    // Clear unsaved changes immediately to remove borders
    setUnsavedChanges({});
    
    const updatedParameters = { ...localParameters };
    Object.keys(unsavedChanges).forEach(name => {
      const originalValue = localParameters[name]?.originalValue;
      if (originalValue !== undefined) {
        updatedParameters[name] = {
          ...updatedParameters[name],
          value: originalValue
        };
      }
    });
    
    setLocalParameters(updatedParameters);
    setRevertStatus('success');
    
    setTimeout(() => {
      setRevertStatus('idle');
      setShowSaveButton(false);
      setShowRevertButton(false);
    }, 5000);
  };

  return (
    <div className="max-w-7xl mx-auto py-6 space-y-6">
      {isFirstLogin && welcomeMessage && (
        <Alert className="mb-6 bg-blue-50 border-blue-200">
          <AlertCircle className="h-4 w-4 text-blue-600" />
          <AlertTitle className="text-blue-800">Welcome</AlertTitle>
          <AlertDescription className="text-blue-700">{welcomeMessage}</AlertDescription>
        </Alert>
      )}

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-900 pl-6">Project Settings</h2>
        <div className="flex space-x-4">
          {isFirstLogin && (
            <button
              onClick={() => navigate('/')}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Skip for Now
            </button>
          )}
          {(Object.keys(unsavedChanges).length > 0 || saveStatus !== 'idle' || revertStatus !== 'idle') && (
            <>
              {showRevertButton && (
                <button
                  onClick={handleRevertAll}
                  disabled={revertStatus === 'success' || saveStatus !== 'idle'}
                  className={`flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium ${
                    revertStatus === 'success' ? 'bg-green-50 border-green-200' : 'bg-white hover:bg-gray-50'
                  } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                >
                  {revertStatus === 'success' ? (
                    <Check className="w-4 h-4 text-green-600" />
                  ) : revertStatus === 'error' ? (
                    <X className="w-4 h-4 text-red-600" />
                  ) : (
                    <>
                      Revert All
                    </>
                  )}
                </button>
              )}
              {showSaveButton && (
                <button
                  onClick={handleSave}
                  disabled={saveStatus !== 'idle' || revertStatus !== 'idle'}
                  className={`flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                    saveStatus === 'success' ? 'bg-green-600' : saveStatus === 'error' ? 'bg-red-600' : 'bg-blue-600 hover:bg-blue-700'
                  } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                >
                  {saveStatus === 'loading' ? (
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  ) : saveStatus === 'success' ? (
                    <Check className="w-4 h-4" />
                  ) : saveStatus === 'error' ? (
                    <X className="w-4 h-4" />
                  ) : (
                    <>
                      <Save className="w-4 h-4 mr-2" />
                      Save All ({Object.keys(unsavedChanges).length})
                    </>
                  )}
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {error && (
        <Alert className="mb-4 bg-red-50">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-lg p-6 space-y-8">
          <h3 className="text-lg font-medium">Edit Parameters</h3>
          <GeneralSection 
            parameters={localParameters} 
            onChange={handleParameterChange}
            fieldMessages={fieldMessages}
            setFieldMessages={setFieldMessages}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            showIndividualButtons={showIndividualButtons}
          />
          <SteelGradeSection 
            parameters={localParameters} 
            onChange={handleParameterChange}
            fieldMessages={fieldMessages}
            setFieldMessages={setFieldMessages}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            showIndividualButtons={showIndividualButtons}
          />
          <CustomParametersSection 
            parameters={localParameters} 
            onChange={handleParameterChange}
            fieldMessages={fieldMessages}
            setFieldMessages={setFieldMessages}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            showIndividualButtons={showIndividualButtons}
          />
          <CustomParameterSection 
            onParameterAdded={() => dispatch(fetchUserParameters())}
            fieldMessages={fieldMessages}
            setFieldMessages={setFieldMessages}
          />
        </div>
        
        <div>
          <ParameterDisplay />
        </div>
      </div>
    </div>
  );
};

export default ParametersManager;