import React, { useState } from "react";
import { useNavigate, Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../slices/authSlice";
import {
  Menu,
  X,
  Settings,
  FileText,
  Users,
  Building,
  LogOut,
} from "lucide-react";
import { useParameterCheck, ParameterPromptModal } from "./ParameterCheck";
import Footer from "./Footer";

const SidebarLayout = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showPrompt, setShowPrompt } = useParameterCheck();

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const sidebarContent = (
    <>
      <div className="p-4 flex justify-between items-center">
        <img
          src="/client/static/vilogo.png"
          alt="Logo"
          className="w-full h-auto"
          style={{ aspectRatio: "1.1 / 1" }}
        />
      </div>

      <nav className="flex flex-col flex-1">
        <div className="flex-1 space-y-1">
          {/* View Documents - Available to all users */}
          <Link
            to="/pdfs"
            className="flex items-center px-4 py-2 hover:bg-gray-700"
          >
            <FileText className="w-5 h-5 mr-2" />
            Projects
          </Link>

          {/* Parameters - Available to all users */}
          <Link
            to="/parameters"
            className="flex items-center px-4 py-2 hover:bg-gray-700"
          >
            <Settings className="w-5 h-5 mr-2" />
            Parameters
          </Link>

          {/* Parent Admin specific links */}
          {auth.user?.role === "parent_admin" && (
            <>
              <Link
                to="/parent-admin"
                className="flex items-center px-4 py-2 hover:bg-gray-700"
              >
                <Building className="w-5 h-5 mr-2" />
                Organization Management
              </Link>
              <Link
                to="/superadmin/pdfs"
                className="flex items-center px-4 py-2 hover:bg-gray-700"
              >
                <FileText className="w-5 h-5 mr-2" />
                All PDFs
              </Link>
            </>
          )}

          {/* Admin specific links */}
          {auth.user?.role === "admin" && (
            <Link
              to="/manage-users"
              className="flex items-center px-4 py-2 hover:bg-gray-700"
            >
              <Users className="w-5 h-5 mr-2" />
              Manage Users
            </Link>
          )}
        </div>
      </nav>
    </>
  );

  const mainContent = (
    <>
      <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
        <Outlet />
      </main>

      <ParameterPromptModal
        isOpen={showPrompt}
        onClose={() => setShowPrompt(false)}
      />
    </>
  );

  return (
    <FixedWidthLayout>
      {{
        sidebar: sidebarContent,
        content: mainContent,
      }}
    </FixedWidthLayout>
  );
};

// Fixed Width Layout Component
const FixedWidthLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const sidebarWidth = 200;

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <div className="flex min-h-screen">
      <div
        className={`fixed h-full bg-gray-800 text-white transition-transform duration-300 z-40 w-52 flex flex-col
          ${
            isSidebarOpen
              ? "translate-x-0"
              : "-translate-x-full lg:translate-x-[-200px]"
          }`}
      >
        {children.sidebar}
        <div className="absolute bottom-0 left-0 w-full border-t border-gray-700 py-4">
          <div className="px-4 text-center text-xs text-gray-400">
            <p>
              As we evolve, we want to work with you.
              <br />
              Please contact
              <br />
              <a
                href="mailto:support@vectorintelligence.org"
                className="hover:text-gray-300 underline"
              >
                support@vectorintelligence.org
              </a>
            </p>
          </div>
        </div>
      </div>

      <div
        className="flex-1 bg-white"
        style={{
          marginLeft: isSidebarOpen ? `${sidebarWidth}px` : "0",
          width: `calc(100% - ${isSidebarOpen ? sidebarWidth : 0}px)`,
          transition: "margin-left 300ms ease-in-out, width 300ms ease-in-out",
        }}
      >
        <div className="sticky top-0 bg-white border-b">
          <div className="flex justify-between items-center h-16 px-4">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="p-2 hover:bg-gray-100 -ml-2"
              >
                {isSidebarOpen ? (
                  <X className="w-6 h-6" />
                ) : (
                  <Menu className="w-6 h-6" />
                )}
              </button>
            </div>
            <div>
              <button
                onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                className="flex items-center space-x-2 hover:bg-gray-100 px-3 py-2 rounded"
              >
                <span>Hi {auth.user?.username}</span>
                <span>{isUserMenuOpen ? "▼" : "▶"}</span>
              </button>
              {isUserMenuOpen && (
                <div className="absolute right-4 mt-2 w-48 bg-white rounded shadow-lg">
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <LogOut className="w-4 h-4 mr-2" />
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {children.content}
      </div>
    </div>
  );
};

export default SidebarLayout;
